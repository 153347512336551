const routes = [
    {// 启动页
        path: '/Welcome',
        component: () =>
            import('../pages/WelcomeA.vue')
    },
    {// 关于瑞摩
        path: '/',
        component: () =>
            import('../pages/AboutRuimo.vue')
    },
    {// Houzzkit
        path: '/Houzzkit',
        component: () =>
            import('../pages/HouzzkitA.vue')
    },
    {// 全宅智能
        path: '/IntelligentProducts',
        component: () =>
            import('../pages/IntelligentProducts.vue')
    },
    {// 项目案例
        path: '/ProjectCase',
        component: () =>
            import('../pages/ProjectCase.vue')
    },
    {// 技术支持
        path: '/TechnicalSupport',
        component: () =>
            import('../pages/TechnicalSupport.vue')
    },
    {// 联系我们
        path: '/ContactUs',
        component: () =>
            import('../pages/ContactUs.vue')
    },
]

export default routes
